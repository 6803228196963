'use-strict'
import React, { useState, useEffect } from 'react'
import Banner from './styles'

const MLHBanner = () => {
  const [show, setShow] = useState(false)
  const checkScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShow(true)
    }
  }

  useEffect(() => {
    const listener = window.addEventListener('scroll', checkScroll)
    return () => window.removeEventListener('scroll', listener)
  }, [])

  return (
    <a
      id="mlh-trust-badge"
      style={{
        display: 'block',
        maxWidth: '100px',
        minWidth: '60px',
        position: 'fixed',
        right: '50px',
        top: '0',
        width: '10%',
        zIndex: '10000'
      }}
      href="https://mlh.io/na?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2025-season&utm_content=white"
      target="_blank"
    >
      <Banner
        src="https://s3.amazonaws.com/logged-assets/trust-badge/2025/mlh-trust-badge-2025-white.svg"
        alt="Major League Hacking 2025 Hackathon Season"
      />
    </a>
  )
}

export default MLHBanner
